import Header from '../../../components/Header'
import { IonContent, IonPage, useIonViewWillEnter } from '@ionic/react'
import { useTranslation } from 'react-i18next'
import { type RouteComponentProps } from 'react-router'
import { useSharedCertificateQuery } from '../../../lib/apollo/types'
import Certificate from '../../../components/Certificate'
import { CLUB_SHORT, SCHOOL_IMAGE } from '../../../lib/apollo/config'

interface SharedPageProps
  extends RouteComponentProps<{
    key: string
  }> {
}
const SharedPage: React.FC<SharedPageProps> = ({ match }) => {
  const { t } = useTranslation(CLUB_SHORT)

  useIonViewWillEnter(() => {
    document.body.classList.add('cert')
  })

  const { data } = useSharedCertificateQuery({
    variables: {
      key: match.params.key
    }
  })

  return (
        <IonPage className='certificate-page camp cert-page'>
            <Header buttonLeft='none' title={data?.sharedCertificate.caption ?? ''}/>
            <IonContent className='camp-wrapper'>
                <div className='certificates' id='certificate-wrapper'>
                    {(data?.sharedCertificate && (
                        <Certificate
                            club={CLUB_SHORT}
                            key={data?.sharedCertificate.camp.id}
                            certificate={data?.sharedCertificate}
                            header={{
                              intro: 'Bei uns bist du der Star'
                            }}
                            footer={{
                              text: t('certificate.footer'),
                              img: '/assets/football-badge-svgrepo-com.svg'
                            }}
                            clubImg='/assets/icon/logo.svg'
                            schoolImg={SCHOOL_IMAGE}
                        />
                    ))}
                </div>
            </IonContent>
        </IonPage>
  )
}

export default SharedPage
