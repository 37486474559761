import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import { useRef } from 'react'

import { ChartType } from '../../lib/apollo/types'

import GlobalDefs from './GlobalDefs'
import Layer, { type LayerI } from './Layer'
import Levels from './Levels'
import TouchArea from './TouchArea'

import './style.scss'
import { useTranslation } from 'react-i18next'
import TalentIDSkeleton from './TalentIDSkeleton'
import { CLUB_SHORT } from '../../lib/apollo/config'

export const translationKeys = (talentClass: '1' | '2') => [
    `i18n.vote.${talentClass === '2' ? 'goalie.' : ''}0`,
    `i18n.vote.${talentClass === '2' ? 'goalie.' : ''}1`,
    `i18n.vote.${talentClass === '2' ? 'goalie.' : ''}2`,
    `i18n.vote.${talentClass === '2' ? 'goalie.' : ''}3`,
    `i18n.vote.${talentClass === '2' ? 'goalie.' : ''}4`,
    `i18n.vote.${talentClass === '2' ? 'goalie.' : ''}5`,
    `i18n.vote.${talentClass === '2' ? 'goalie.' : ''}6`,
    `i18n.vote.${talentClass === '2' ? 'goalie.' : ''}7`
]

interface TalentIDProps {
  values?: number[]
  interactive?: boolean
  className?: string
  backgroundColor?: 'primary' | 'secondary' | 'tertiary' | 'black' | 'secondary-transparent' | 'scout' | 'roleModel'
  color1?: 'primary' | 'secondary' | 'tertiary' | 'black'
  color2?: 'primary' | 'secondary' | 'tertiary' | 'black'
  id?: string
  animationIndex?: number
  disableEnterAnimation?: boolean
  talentClass: '1' | '2'
}

const TalentID: React.FC<TalentIDProps> = ({
  color1 = 'primary',
  color2 = 'tertiary',
  backgroundColor = 'primary',
  className,
  interactive,
  id,
  disableEnterAnimation = false,
  values,
  talentClass

}) => {
  // textPath fix (multiple svgs with same id leads to wrong path calculation)
  const uuidRef = useRef(uuid())

  const { t } = useTranslation(CLUB_SHORT)

  const layer: LayerI = {
    id: 'global',
    withHover: false,
    withGroupLabels: false,
    values: values ?? [],
    type: ChartType.Radar,
    translationKeys: translationKeys(talentClass),
    translations: translationKeys(talentClass).map(key => t(key))
  }

  if (!values) return <TalentIDSkeleton />

  return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            preserveAspectRatio='xMidYMid meet'
            viewBox='-60 -60 620 620'
            className={clsx(
              'layer-global',
                `layer-global__background--${backgroundColor}`,
                `layer-global__color1--${color1}`,
                `layer-global__color2--${color2}`,
                className,
                { interactive, animated: !disableEnterAnimation }
            )}
            id={id}
        >
            <GlobalDefs
                id={uuidRef.current}
            />

            <Layer
                id={uuidRef.current}
                layer={layer}
            />

            <Levels />

            <TouchArea
                onClick={() => {
                  // setActiveIndex(aId => (aId + 1) % layers.length)
                }}
            />
        </svg>
  )
}

export default TalentID
