import './style.scss'
import { type Camp, useCertificateQuery } from '../../lib/apollo/types'
import {
  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonViewWillEnter
} from '@ionic/react'
import { Trans, useTranslation } from 'react-i18next'
import { signOut, useUser } from '../../providers/Auth/hooks'
import Header from '../../components/Header'
import { format } from 'date-fns'
import { logOutOutline } from 'ionicons/icons'
import Button from '../../components/Button'
import { Browser } from '@capacitor/browser'
import Certificate from '../../components/Certificate'
import { CLUB_IMAGE, CLUB_SHORT, FE_URL } from '../../lib/apollo/config'
import Divider from '../../components/Divider'

const dateFormat = new Intl.DateTimeFormat('de-DE', { dateStyle: 'short' })

const CertificatePage = () => {
  useIonViewWillEnter(() => {
    document.body.classList.add('cert')
  })

  const { data } = useCertificateQuery()
  const { t } = useTranslation()
  const { t: clubT } = useTranslation(CLUB_SHORT)

  const user = useUser()

  const camps = data?.certificate?.map(certificate => certificate.camp) ?? []

  // sort camps by end date
  camps.sort((a, b) => {
    if (!a.end || !b.end) return 0
    return new Date(a.end).getTime() - new Date(b.end).getTime()
  })

  // filter double entries of camps
  const uniqueCamps = camps.filter((camp, index, self) =>
    index === self.findIndex((t) => (
      t.id === camp.id
    ))
  )
  // group uniqueCamps by end date
  const groupedCamps: Record<string, Camp[]> = {}
  uniqueCamps.forEach(camp => {
    // end is month and year string
    const end = `${t(`animation_label.m.${+format(new Date(camp.end ?? ''), 'M')}`)} ${format(new Date(camp.end ?? ''), 'yyyy')}`
    if (!end) return

    if (!groupedCamps[end]) {
      groupedCamps[end] = []
    }
    groupedCamps[end].push(camp)
  })

  return (
        <IonPage className='certificate-page cert-page'>
            <Header title={t('title.certificates')}><Button icon={logOutOutline} onClick={async () => { await signOut() }} /></Header>
            <IonContent>
                <div className='card'>
                    <IonGrid>
                        <IonRow>
                            {(user?.user.given_name && (
                                <>
                                    <h2 className='hsv-font-slab ion-no-margin'>
                                        Hallo {user?.user.given_name}
                                    </h2>
                                    <div className='ion-margin-top ion-margin-bottom n2br'>
                                        <IonText>
                                            {clubT('certificate.welcomeText')}
                                        </IonText>
                                    </div>
                                </>
                            ))}
                            <div className='camps cert_page_full-width'>
                                {(uniqueCamps ?? []).length === 0 && (
                                    <div className='ion-margin-top ion-margin-bottom n2br'>
                                        <IonText>
                                            {clubT('certificate.noCertificate')}
                                        </IonText>
                                    </div>
                                )}
                                {Object.entries(groupedCamps).map(([date, camps]) => (
                                    <div key={date} className='cert_page_full-width'>
                                        <IonGrid className='ion-no-padding'>
                                            <IonRow>
                                                <IonCol size='12'>
                                                    <h3 style={{ marginBottom: '4px' }}>{date}</h3>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow className='spacing-4'>
                                                {camps.map(camp => (
                                                    <IonCol sizeXs='12' sizeMd='4' key={camp.id}>
                                                        <IonCard className='certificate-camp-card ion-no-margin'
                                                                routerLink={`/certificate/camp/${camp.id}`}>
                                                            <img src={camp.image ?? ''} alt={camp.caption ?? 'camp-img'}/>
                                                            <IonCardHeader>
                                                            <IonCardTitle>{camp.caption}</IonCardTitle>
                                                                <IonCardSubtitle>{camp.type}</IonCardSubtitle>
                                                            </IonCardHeader>
                                                            <IonCardContent>
                                                                <div>{dateFormat.format(new Date(camp.start ?? ''))} - {dateFormat.format(new Date(camp.end ?? ''))}</div>
                                                                <div>{camp.ageMin} - {camp.ageMax} Jahre</div>
                                                            </IonCardContent>
                                                        </IonCard>
                                                    </IonCol>
                                                ))}
                                            </IonRow>
                                        </IonGrid>
                                    </div>
                                ))}
                            </div>
                        </IonRow>
                    </IonGrid>
                </div>
                <Divider dark />
                <div className='card'>
                    <IonGrid>
                        <IonRow className='cert-page__intro'>
                            <IonCol sizeMd='6' size='12'>
                                <Certificate certificate={{
                                  __typename: undefined,
                                  id: '',
                                  talentId: undefined,
                                  participantId: '1',
                                  caption: 'Maxi Meyerhoff',
                                  vote: [75, 60, 65, 50, 48, 75, 80, 90],
                                  image: FE_URL + 'examples/talent.jpeg',
                                  birthday: '2014-01-01',
                                  firstName: 'Maxi',
                                  lastName: 'Meyerhoff',
                                  talentClass: 1,
                                  camp: {
                                    __typename: undefined,
                                    id: '',
                                    type: undefined,
                                    ageMax: undefined,
                                    ageMin: undefined,
                                    caption: 'Somemrcamp 2024',
                                    description: undefined,
                                    start: undefined,
                                    end: new Date().toUTCString(),
                                    number: undefined,
                                    image: undefined,
                                    participantsCount: undefined,
                                    finished: undefined,
                                    campDirector: undefined,
                                    trainers: undefined
                                  }
                                }}
                                             clubImg={CLUB_IMAGE}
                                             club={CLUB_SHORT}
                                             header={{
                                               intro: ''
                                             }}
                                />
                            </IonCol>
                            <IonCol class='display-flex display-flex-column ion-justify-content-end'>
                                <img className='cert-page__intro__arrow' src='/assets/arrow.svg' alt='arrow'/>
                                <div className='cert-page__intro__jmt'>
                                    <IonImg src='/assets/logo_with_text.svg'/>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className='ion-margin-top'>
                                <div style={{ paddingBottom: '4px' }}>
                                    <IonText color='danger'>{clubT('cert.ad.cooperation')}</IonText>
                                </div>
                                <h1 className='hsv-font-slab ion-no-margin'>{clubT('cert.ad.title')}</h1>
                                <div className='n2br ion-margin-top'>
                                    <IonText>
                                        <Trans
                                            i18nKey='cert.ad.text'
                                            ns={CLUB_SHORT}
                                        >
                                            cert.ad.text
                                            <IonText
                                                onClick={async () => {
                                                  await Browser.open({ url: 'https://app.joinmytalent.com' })
                                                }}
                                                className='color-jmt cursor-pointer'
                                                rel="noreferrer"
                                            >
                                                link
                                            </IonText>
                                            cert.ad.text
                                        </Trans>
                                    </IonText>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
  )
}

export default CertificatePage
